<script lang="ts" setup>
useHead({ title: 'Přihlásit se' })
const userStore = useUser()
const { isLogged, user } = storeToRefs(userStore)
const { components: { navigation: { hideSign } } } = useAppConfig()
</script>

<template>
  <div v-if="isLogged" fubex-container max-w-2xl mx-auto flex flex-col justify-center items-center gap-24px my-80px>
    <div flex flex-col items-center>
      <div paragraph-md>
        Uživatel:
      </div>
      <div heading5 font-600>
        {{ user!.name }}
      </div>
    </div>
    <NuxtLink to="/profile/overview">
      <FButton name="login-account" size="lg" prefix-icon="i-fubex-user">
        Můj účet
      </FButton>
    </NuxtLink>
    <NuxtLink to="/logout">
      <FButton name="login-signout" size="lg" variant="text">
        Odhlásit
      </FButton>
    </NuxtLink>
  </div>
  <div v-else fubex-container max-w-2xl mx-auto flex flex-col justify-center items-center gap-16px my-80px>
    <h2 heading5 font-600>
      Nepřihlášený uživatel
    </h2>
    <UnoIcon i-fubex-user h-80px w-80px />
    <WidgetAuthDialogs default-view="login">
      <FButton name="login-login" size="lg" prefix-icon="i-fubex-user">
        Přihlásit se
      </FButton>
    </WidgetAuthDialogs>
    <WidgetAuthDialogs default-view="register">
      <FButton v-if="!hideSign" name="login-register" size="lg" variant="text">
        Vytvořit účet
      </FButton>
    </WidgetAuthDialogs>
  </div>
</template>
